<template>
    <footer class="footer pt-1">
		<div class="container footer-container">

			<div class="footer-col">
				<h3 class="footer-col__heading">Social Media</h3>
				<ul class="footer-col__list">
					<a href="https://www.instagram.com/dig_advising/" class="button button--primary social">INSTAGRAM</a>
					<a href="https://www.linkedin.com/company/d-i-g-advising/" class="button button--primary social">LINKEDIN</a>
				</ul>
			</div>
		</div>
		<div class="container text-center footer__copyright">
			<p class="pt-1 footer_copyright">&copy; {{ new Date().getFullYear() }} - DIG Advising - KvK-nummer: 84998563 - Created by Arvind Mehairjan.</p>
		</div>
	</footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
