<template>
  <div id="mainApp">
    <SkipContent />
    <Header />
    <Hero />
    <Footer />
  </div>
</template>

<script>
import SkipContent from './components/SkipContent.vue'
import Header from './components/Header.vue'
import Hero from './components/Hero.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    SkipContent, Header, Hero, Footer
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');
@import "./assets/styles/main.scss"

</style>
