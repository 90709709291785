<template>
  <a href="#main" class="skip-to-content">
    Skip to content
  </a>
</template>

<script>
export default {
  name: 'SkipContent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
