<template>
<main>
	<section class="hero" id="home">
		<div class="hero__image">
			<img src="@/assets/images/hero.jpg" loading="lazy">
		</div>
		<div class="container hero__container">
			<div class="hero-wrapper">
				<h1 class="hero-wrapper__heading">Diversity. <span>Inclusivity.</span> Growth.</h1>
					<a href="#mission" class="button button--primary" data-content="Find Out More" area-label="Find out more">Find out More</a>
				</div>
			</div>
		</section>

		<section class="get-started get-started-img1 mt-1" id="mission">
			<div class="overlay"></div>
			<div class="container">
				<h1 class="training-option__heading text-center"><span>Mission</span></h1>
					<p class="training-options__body text-center">The mission at DIG is to develop diversity, inclusivity and growth within professional services firms across all sectors.</p>
					<p class="training-options__body text-center"><br><i>"You have to <b>DIG</b> deeper to find growth."</i></p>
			</div>
		</section>

		<section class="training-options pt-1" id="services">
			<div class="container">
				<h1 class="training-option__heading text-center"><span>Services</span></h1>

			<div class="training training--options pt-1">
				<a href="#" class="training__card">
					<h3 class="training__card-title">Intake and Quick Scan</h3>
					<div class="image-overlay"></div>
					<div class="training__image">
						<img src="@/assets/images/box_4.jpg" alt="Small Group PT">
					</div>
				</a>

				<a href="#" class="training__card">
					<h3 class="training__card-title">Culture Change</h3>
					<div class="image-overlay"></div>
					<div class="training__image">
						<img src="@/assets/images/box_5.jpg" alt="Classes">
					</div>
				</a>

				<a href="#" class="training__card">
					<h3 class="training__card-title">D&I Strategy Development</h3>
					<div class="image-overlay"></div>
					<div class="training__image">
						<img src="@/assets/images/box_6.jpg" alt="One To One">
					</div>
				</a>

				<a href="#" class="training__card">
					<h3 class="training__card-title">Inclusive Leadership</h3>
					<div class="image-overlay"></div>
					<div class="training__image">
						<img src="@/assets/images/box_7.jpg" alt="One To One">
					</div>
				</a>

				<a href="#" class="training__card">
					<h3 class="training__card-title">Awareness programme</h3>
					<div class="image-overlay"></div>
					<div class="training__image">
						<img src="@/assets/images/box_8.jpg" alt="One To One">
					</div>
				</a>

				<a href="#" class="training__card">
					<h3 class="training__card-title">Business Development Advice</h3>
					<div class="image-overlay"></div>
					<div class="training__image">
						<img src="@/assets/images/box_9.jpg" alt="One To One">
					</div>
				</a>
			</div>
			</div>
		</section>

		<section class="get-started get-started-img2 mt-1" id="contact">
			<div class="overlay"></div>
			<div class="container">
				<h1 class="text-center">
					WANT TO GET IN TOUCH?<br>
          <span>SEND AN EMAIL.</span>
				</h1>
				<a href="mailto:contact@digadvising.com" class="button button--primary">CONTACT</a>
			</div>
		</section>
</main>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
