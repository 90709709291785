<template>
      <div class="container">
            <nav>
                  <input type="checkbox" id="nav" class="hidden">
                  <label for="nav" class="nav-btn">
                        <i></i>
                        <i></i>
                        <i></i>
                  </label>
                      <div class="nav-wrapper">
                        <ul>
                              <li><a class="nav-links" href="#home">Home</a></li>
                              <li><a class="nav-links" href="#mission">Mission</a></li>
                              <li><a class="nav-links" href="#services">Services</a></li>
                              <li><a class="nav-links" href="#contact">Contact</a></li>
                        </ul>
                  </div>
            </nav>
      </div>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    toggleMenu () {
      const mobileMenuToggle = this.$refs.mobileMenuToggleRef
      const mobileMenu = this.$refs.mobileMenuRef
      const mobileMenuClose = this.$refs.mobileNavCloseRef

      // Open Menu
      mobileMenuToggle.addEventListener('click', () => {
        mobileMenu.classList.add('is-active')
      })

      // Close Menu
      mobileMenuClose.addEventListener('click', () => {
        mobileMenu.classList.remove('is-active')
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
